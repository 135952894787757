<template> 
  <div class="air-quality" v-if="airQuality !== null">
    <div class="info-card">
      <h2><i class="fas fa-wind"></i> Yesterday's Air Quality</h2>
      <p v-if="airQuality">
        <i class="fas fa-smog"></i> Air Quality Index (AQI): {{ airQuality.airQuality.aqi }}
      </p>
      <p v-if="airQuality">
        <i class="fas fa-temperature-high"></i> PM2.5 Levels: {{ airQuality.airQuality.components.pm2_5 }} µg/m³
      </p>
      <p v-if="airQuality">
        <i class="fas fa-temperature-low"></i> PM10 Levels: {{ airQuality.airQuality.components.pm10 }} µg/m³
      </p>
      <p v-if="airQuality">
        <i class="fas fa-temperature-low"></i> NO3 Levels: {{ airQuality.airQuality.components.no2 }} µg/m³
      </p>
      <p v-if="airQuality">
        <i class="fas fa-temperature-low"></i> CO Levels: {{ airQuality.airQuality.components.co }} µg/m³
      </p>
      <p v-else>
        No air quality data available for yesterday.
      </p>
    </div>
    
    <!-- Displaying calculated waste emissions -->
    <div class="info-card">
      <h2><i class="fas fa-recycle"></i> Yesterday's Solid Waste Emissions</h2>
      <p>
        <i class="fas fa-trash"></i> Average Daily Waste Emissions: {{ averageDailyWaste.toFixed(0) }} kg
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AirQuality',
  data() {
    return {
      averageWastePerPerson: 850, // Average waste per person in kg
      dayFactors: {
        'Monday': 1,
        'Tuesday': 1.08,
        'Wednesday': 0.5,
        'Thursday': 1.14,
        'Friday': 0.95,
        'Saturday': 1,
        'Sunday': 0.8,
      },
      previousDate: '',
      dayFactor: 1,
      averageDailyWaste: 0
    };
  },
  computed: {
    ...mapGetters({
      airQuality: 'city/airQuality',  // Getter to get air quality data from Vuex store
      cityData: 'city/cityData'       // Getter to get city data from Vuex store
    }),
  },
  mounted() {
    if (this.airQuality === null && this.cityData && this.cityData.id) {  // Check if airQuality is null and cityData is loaded
      this.getYesterdayAirQuality(this.cityData.id);
    }
    this.calculateWasteEmissions(); // Call the method when component is mounted
  },
  methods: {
    calculateWasteEmissions() {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      const previousDate = yesterday.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' });
      this.previousDate = previousDate;
      const previousDay = yesterday.toLocaleString('en-us', { weekday: 'long' });
      this.dayFactor = this.dayFactors[previousDay];
      
      // Calculate waste emissions
      this.averageDailyWaste = this.averageWastePerPerson * this.dayFactor;
    },
    ...mapActions({
      getYesterdayAirQuality: 'city/getYesterdayAirQuality',  // Action to fetch yesterday's air quality data
    })
  }
};
</script>

<style scoped>
.air-quality {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f0f8ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-card {
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.info-card h2 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #0077b6;
}

.info-card p {
  font-size: 18px;
  margin: 5px 0;
}

.info-card p i {
  margin-right: 8px;
}

/* Import FontAwesome styles */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css');
</style>